// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import Cookie from "js-cookie"
global.$ = require("jquery");
import "jquery-ui";
import "bootstrap"
import '../stylesheets/application.scss'

require("trix")
require("@rails/actiontext")

Rails.start()
ActiveStorage.start()
require.context('../images');
import { smoothScrollAnchor } from "smooth-scroll-anchor";

const copyText = (element) => {
  const baseText = element.parentElement.querySelector("input").value;
  element.parentElement.querySelector("input").select();
  document.execCommand('copy');
  element.parentElement.querySelector("input").value = 'Copié dans le presse papier';
  setTimeout(() => {
    element.parentElement.querySelector("input").value = baseText;
  }, 1500);
};


window.copyText = copyText;
require("trix")
require("@rails/actiontext")

document.addEventListener('DOMContentLoaded', () => {
  smoothScrollAnchor({
      behaviour: "smooth", // [smooth | auto] Smooth animated scroll or instant
      block: "start", // [start | center | end | nearest] Alignment of the target element when it's finished scrolling
      offset: 56 // Optional offset of the target scroll position. Handy if you have a fixed header!
    }
  );
  // manualScroll(window.location.hash);
  var btn = $('#button');

  $(window).scroll(function() {
    if ($(window).scrollTop() > 300) {
      btn.addClass('show');
    } else {
      btn.removeClass('show');
    }
  });

  btn.on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop:0}, '300');
  });

  $('#exampleModal').modal('show')
});

class CookieBar {
  constructor() {
    this.cookiesBar = document.getElementById('cookies-bar');
  }

  init() {
    if (this.cookiesAllowed()) {
      this.appendGACode();
    }

    this.addButtonBehaviors();
  }

  cookiesAllowed() {
    return Cookies.get('allow_cookies') === 'yes';
  }

  addButtonBehaviors() {
    if (!this.cookiesBar) {
      return;
    }

    this.cookiesBar.querySelector('.accept').addEventListener('click', () => this.allowCookies(true));

    this.cookiesBar.querySelector('.reject').addEventListener('click', () => this.allowCookies(false));
  }

  appendGACode() {
    const ga = "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){" +
      "(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o)," +
      "m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)" +
      "})(window,document,'script','//www.google-analytics.com/analytics.js','ga');" +
      "ga('create', 'UA-XXXXX-Y', 'auto');" + "ga('send', 'pageview');";

    document.getElementsByTagName('head')[0].append('<script>' + ga + '</script>');
  }

  allowCookies(allow) {
    if (allow) {
      Cookies.set('allow_cookies', 'yes', {
        expires: 365
      });

      this.appendGACode();
    } else {
      Cookies.set('allow_cookies', 'no', {
        expires: 365
      });
    }

    this.cookiesBar.classList.add('hidden');
  }
}

window.onload = function() {
  // $('body').bind('copy', function(e) {
  //   e.preventDefault();
  //   alert("Ce site est sous ©copyright. La copie de son contenu est interdite.");
  // });
  const cookieBar = new CookieBar();

  cookieBar.init();
};

window.addEventListener('contextmenu', function(event){
  event.preventDefault();
  alert("Ce site est sous ©copyright. La copie de son contenu est interdite.");
});


